<template>
  <div class="builder">
    <div class="card card-custom gutter-b">
      <div class="card-header card-header-tabs-line" v-if="false">
        <ul class="nav nav-dark nav-bold nav-tabs nav-tabs-line" role="tablist" ref="builder-tab">
          <li class="nav-item">
            <a
              class="nav-link active"
              v-on:click="setActiveTab"
              data-tab="0"
              data-toggle="tab"
              href="#"
              role="tab"
            >
              Page
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              v-on:click="setActiveTab"
              data-tab="1"
              data-toggle="tab"
              href="#"
              role="tab"
            >
              Header
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              v-on:click="setActiveTab"
              data-tab="2"
              data-toggle="tab"
              href="#"
              role="tab"
            >
              Subheader
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              v-on:click="setActiveTab"
              data-tab="3"
              data-toggle="tab"
              href="#"
              role="tab"
            >
              Content
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              v-on:click="setActiveTab"
              data-tab="4"
              data-toggle="tab"
              href="#"
              role="tab"
            >
              Aside
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              v-on:click="setActiveTab"
              data-tab="5"
              data-toggle="tab"
              href="#"
              role="tab"
            >
              Footer
            </a>
          </li>
        </ul>
      </div>
      <!--begin::Form-->
      <form class="form" v-on:submit="submit($event)">
        <div class="card-body" v-if="false">
          <b-tabs class="hide-tabs" v-model="tabIndex">
            <b-tab active>
              <div class="form-group row">
                <label class="col-lg-3 col-form-label">
                  Page Loader:
                </label>
                <div class="col-lg-9 col-xl-4">
                  <select class="form-control" v-model="config.loader.type">
                    <option value="false">
                      Disabled
                    </option>
                    <option value="spinner-logo" selected="selected">
                      Spinner &amp; Logo
                    </option>
                  </select>
                  <div class="form-text text-muted">
                    Select page loading indicator.
                  </div>
                </div>
              </div>
            </b-tab>

            <b-tab>
              <div class="form-group row">
                <label class="col-lg-3 col-form-label">
                  Desktop Fixed Header:
                </label>
                <div class="col-lg-9 col-xl-4">
                  <span class="switch switch-icon">
                    <label>
                      <input
                        type="checkbox"
                        v-model="config.header.self.fixed.desktop"
                        value="true"
                        checked=""
                      />
                      <span></span>
                    </label>
                  </span>
                  <div class="form-text text-muted">
                    Enable fixed header for desktop mode
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-lg-3 col-form-label">
                  Desktop Fixed Top Header:
                </label>
                <div class="col-lg-9 col-xl-4">
                  <span class="switch switch-icon">
                    <label>
                      <input
                        type="checkbox"
                        v-model="config.topHeader.self.fixed.desktop"
                        value="true"
                        checked=""
                      />
                      <span></span>
                    </label>
                  </span>
                  <div class="form-text text-muted">
                    Enable fixed top header for desktop mode
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-lg-3 col-form-label">
                  Mobile Fixed Header:
                </label>
                <div class="col-lg-9 col-xl-4">
                  <span class="switch switch-icon">
                    <label>
                      <input
                        type="checkbox"
                        v-model="config.header.self.fixed.mobile"
                        value="true"
                        checked=""
                      />
                      <span></span>
                    </label>
                  </span>
                  <div class="form-text text-muted">
                    Enable fixed header for mobile mode
                  </div>
                </div>
              </div>

              <div class="form-group row">
                <label class="col-lg-3 col-form-label">
                  Display Header Menu:
                </label>
                <div class="col-lg-9 col-xl-4">
                  <span class="switch switch-icon">
                    <label>
                      <input
                        type="checkbox"
                        v-model="config.header.menu.self.display"
                        value="true"
                        checked=""
                      />
                      <span></span>
                    </label>
                  </span>
                  <div class="form-text text-muted">
                    Display header menu
                  </div>
                </div>
              </div>

              <div class="form-group row">
                <label class="col-lg-3 col-form-label">
                  Header Menu Layout:
                </label>
                <div class="col-lg-9 col-xl-4">
                  <select class="form-control" v-model="config.header.menu.self.layout">
                    <option value="default" selected="">Default</option>
                    <option value="tab">Tab</option>
                  </select>
                  <div class="form-text text-muted">
                    Select header menu layout style
                  </div>
                </div>
              </div>

              <div class="form-group row">
                <label class="col-lg-3 col-form-label">
                  Header Menu Arrows:
                </label>
                <div class="col-lg-9 col-xl-4">
                  <span class="switch switch-icon">
                    <label>
                      <input
                        type="checkbox"
                        v-model="config.header.menu.self['root-arrow']"
                        value="true"
                      />
                      <span></span>
                    </label>
                  </span>
                  <div class="form-text text-muted">
                    Enable header menu root link arrows
                  </div>
                </div>
              </div>
            </b-tab>

            <b-tab>
              <div class="form-group row">
                <label class="col-lg-3 col-form-label">
                  Display Subheader:
                </label>
                <div class="col-lg-9 col-xl-4">
                  <span class="switch switch-icon">
                    <label>
                      <input
                        type="checkbox"
                        v-model="config.subheader.display"
                        value="true"
                        checked=""
                      />
                      <span></span>
                    </label>
                  </span>
                  <div class="form-text text-muted">Display subheader</div>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-lg-3 col-form-label">
                  Fixed Subheader:
                </label>
                <div class="col-lg-9 col-xl-4">
                  <span class="switch switch-icon">
                    <label>
                      <input
                        type="checkbox"
                        v-model="config.subheader.fixed"
                        value="true"
                        checked=""
                      />
                      <span></span>
                    </label>
                  </span>
                  <div class="form-text text-muted">
                    Enable fixed(sticky) subheader. Requires
                    <code>Solid</code> subheader style.
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-lg-3 col-form-label">Width:</label>
                <div class="col-lg-9 col-xl-4">
                  <select class="form-control" v-model="config.subheader.width">
                    <option value="fluid" selected="">Fluid</option>
                    <option value="fixed">Fixed</option>
                  </select>
                  <div class="form-text text-muted">
                    Select layout width type.
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-lg-3 col-form-label">Subheader Style:</label>
                <div class="col-lg-9 col-xl-4">
                  <select class="form-control" v-model="config.subheader.style">
                    <option value="transparent">Transparent</option>
                    <option value="solid" selected="">Solid</option>
                  </select>
                  <div class="form-text text-muted">
                    Select subheader style
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-lg-3 col-form-label">Subheader Layout:</label>
                <div class="col-lg-9 col-xl-4">
                  <select class="form-control" v-model="config.subheader.layout">
                    <option value="subheader-v1" selected="">
                      Subheader v1
                    </option>
                  </select>
                  <div class="form-text text-muted">
                    Select subheader layout
                  </div>
                </div>
              </div>
            </b-tab>

            <b-tab>
              <div class="form-group row">
                <label class="col-lg-3 col-form-label">Width:</label>
                <div class="col-lg-9 col-xl-4">
                  <select class="form-control" v-model="config.content.width">
                    <option value="fluid">Fluid</option>
                    <option value="fixed" selected="">Fixed</option>
                  </select>
                  <div class="form-text text-muted">
                    Select layout width type.
                  </div>
                </div>
              </div>
            </b-tab>

            <b-tab>
              <div class="form-group row">
                <label class="col-lg-3 col-form-label">Display:</label>
                <div class="col-lg-9 col-xl-4">
                  <span class="switch switch-icon">
                    <label>
                      <input
                        type="checkbox"
                        v-model="config.aside.self.display"
                        value="true"
                        checked=""
                      />
                      <span></span>
                    </label>
                  </span>
                  <div class="form-text text-muted">Display aside</div>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-lg-3 col-form-label">Fixed:</label>
                <div class="col-lg-9 col-xl-4">
                  <span class="switch switch-icon">
                    <label>
                      <input
                        type="checkbox"
                        v-model="config.aside.self.fixed"
                        value="true"
                        checked=""
                      />
                      <span></span>
                    </label>
                  </span>
                  <div class="form-text text-muted">
                    Set fixed aside layout
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-lg-3 col-form-label">Minimize:</label>
                <div class="col-lg-9 col-xl-4">
                  <span class="switch switch-icon">
                    <label>
                      <input
                        type="checkbox"
                        v-model="config.aside.self.minimize.toggle"
                        value="true"
                        checked=""
                      />
                      <span></span>
                    </label>
                  </span>
                  <div class="form-text text-muted">
                    Allow aside minimizing
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-lg-3 col-form-label">
                  Default Minimize:
                </label>
                <div class="col-lg-9 col-xl-4">
                  <span class="switch switch-icon">
                    <label>
                      <input
                        type="checkbox"
                        v-model="config.aside.self.minimize.default"
                        value="true"
                      />
                      <span></span>
                    </label>
                  </span>
                  <div class="form-text text-muted">
                    Set aside minimized by default
                  </div>
                </div>
              </div>
            </b-tab>

            <b-tab>
              <div class="form-group row">
                <label class="col-lg-3 col-form-label">Fixed Footer:</label>
                <div class="col-lg-9 col-xl-4">
                  <span class="switch switch-icon">
                    <label>
                      <input type="checkbox" v-model="config.footer.fixed" value="true" />
                      <span></span>
                    </label>
                  </span>
                  <div class="form-text text-muted">Set fixed footer</div>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-lg-3 col-form-label">Width:</label>
                <div class="col-lg-9 col-xl-4">
                  <select class="form-control" v-model="config.footer.width">
                    <option value="fluid" selected="">Fluid</option>
                    <option value="fixed">Fixed</option>
                  </select>
                  <div class="form-text text-muted">
                    Select layout width type.
                  </div>
                </div>
              </div>
            </b-tab>
          </b-tabs>
        </div>

        <div class="card-footer">
          <div class="row">
            <div class="col-lg-3"></div>
            <div class="col-lg-9">
              <button type="submit" class="btn btn-primary font-weight-bold" v-if="false">
                <i class="la la-eye"></i>
                Preview
              </button>
              &nbsp;
              <button @click.prevent="returnOriginLayout" class="btn btn-success font-weight-bold">
                <i class="la la-recycle"></i>
                Trở về phiên bản cũ
              </button>
              &nbsp;
              <button type="submit" @click.prevent="tranformNewLayout" class="btn btn-outline-light font-weight-bold">
                <i class="la la-eye"></i>
                Chuyển sang phiên bản mới
              </button>
             
            </div>
          </div>
        </div>
      </form>
      <!--end::Form-->
    </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import newVersionConfig from '@/core/config/_layout.config.json';

export default {
  name: 'builder',
  components: {},
  data() {
    return {
      tabIndex: 0,
      CONFIG_ORIGIN: newVersionConfig,
    };
  },
  computed: {
    ...mapGetters(['layoutConfig']),

    config() {
      console.log('this.layout', this.layoutConfig())
      return this.layoutConfig();
    },
  },
  mounted() {
    // set the tab from previous
    this.setActivePreviousTab();

    this.$store.dispatch(SET_BREADCRUMB, [{ title: 'Thay đổi bố cục' }]);

    this.$nextTick(() => {
      const hljs = this.$el.querySelectorAll('.hljs');
      hljs.forEach(hl => {
        hl.classList.remove('hljs');
        hl.classList.add(`language-${hl.classList[1]}`);
      });
    });
  },
  destroyed() {
    localStorage.removeItem('builderTab');
  },
  methods: {
    returnOriginLayout(){
      // remove existing saved config
      localStorage.removeItem('config');
      window.location.reload();
    },
    /**
     * Reset config
     */
     tranformNewLayout() {
      // remove existing saved config
      // localStorage.removeItem('config');
      localStorage.setItem('config', JSON.stringify(this.CONFIG_ORIGIN));
      window.location.reload();
    },

    /**
     * Set previous tab active
     */
    setActivePreviousTab() {
      this.tabIndex = parseInt(localStorage.getItem('builderTab')) || 0;
      const links = this.$refs['builder-tab'].querySelectorAll('.nav-link');
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove('active');
      }
      this.$refs['builder-tab']
        .querySelector(`[data-tab="${this.tabIndex}"]`)
        .classList.add('active');
    },
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll('.nav-link');
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove('active');
      }

      // set clicked tab index to bootstrap tab
      this.tabIndex = parseInt(event.target.getAttribute('data-tab'));

      // set current active tab
      event.target.classList.add('active');

      // keep active tab
      localStorage.setItem('builderTab', this.tabIndex);
    },

    /**
     * Submit form
     * @param event
     */
    submit(event) {
      event.preventDefault();
      // save new config to localStorage
      localStorage.setItem('config', JSON.stringify(this.config));
      window.location.reload();
    },
  },
};
</script>

<style lang="scss">
.builder {
  /* hide default vue-bootstrap tab links */
  .hide-tabs > div:not(.tab-content) {
    display: none;
  }
}
</style>
